
.main-wrapper{
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 0px 20px;
    }
  }
  .dialog-footer{
    text-align: center;
    margin: 150px 0 50px;
  }
}
